import {Component, DoCheck, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {DattaConfig} from '../../../../../app-config';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { User } from 'src/app/model/User';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  currentUser: User;
  loading = false;
  fullname: any;

  constructor(config: NgbDropdownConfig, private router: Router,
    private authenticationService: AuthenticationService) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    //localStorage
    this.fullname = JSON.parse(localStorage.getItem('currentUser'))['user']['fullname'];
   // console.log("full== " + this.fullname);
  }

  ngOnInit() {
  }

  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.dattaConfig['rtl-layout'] = true;
    } else {
      this.dattaConfig['rtl-layout'] = false;
    }
  }

  logout() {
    this.authenticationService.logout(); 
   // this.router.navigate(['/login']); 

   localStorage.removeItem('logout-change-pw');
   localStorage.removeItem('logout');

    this.router.navigate(['auth', 'signin']);
  }

  doimatkhau() {
    const url = window.location.protocol + "//" + window.location.host + window.location.pathname + "#/changepw";
   // window.open(url, 'Đổi mật khẩu', 'width=600,height=600,left=100 top=100"');
    this.popupwindow(url,'Đổi mật khẩu',600,600);
  }

  thongtincanhan() {
    const url = window.location.protocol + "//" + window.location.host + window.location.pathname + "#/personalinfor";
    // window.open(url, 'Thông tin cá nhân', 'width=600,height=700,left='+ left + ' top='+top);
   this.popupwindow(url,'Thông tin cá nhân',600,700);
  }

  popupwindow(url, title, w, h) {
    var left = (screen.width/2)-(w/2);
    var top = (screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
}
